import React, { Component } from "react"

export class Home extends Component {
  render() {
    return (
      <main>
        <article>
          <div>
            <div>
              <h4>Hi!</h4>
              <p>
                My name is Łukasz Rybka and currently I'm a CTO and co-founder
                of{" "}
                <a
                  href="https://cloudcorridor.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cloud Corridor LLC
                </a>
                . On day to day basis I'm programming, managing, giving lectures
                at Tricity universities, doing commercial trainings for
                front-end and Java developers and working with non-profit IT
                initiatives.
              </p>
              <p>
                I'm an advocate of agility, pragmatism, continuous personal
                development and knowledge sharing.
              </p>
            </div>
          </div>
        </article>
      </main>
    )
  }
}
